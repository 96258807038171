.contact-margin{
    margin: 100px 0;
}
.text-contact{
    color: rgba(58, 50, 116, 0.822);
}
.send-btn{
    background-color: #1a8761 !important;
    color: white;
}
.width{
    width: 365px;
}
@media screen and (max-width: 600px){
    .width{
        width: 100%;
    }
  }

