.custom-bg{
    background-color: aliceblue;
}
.custom-parent{
    margin-top: 100px;
}
.custom-image{
    width: 100%;
    height: 300px;
}
.custom-soft-img{
    width: 650px;
}
.res-custom-image{
    position: relative;
}
.custom-logo{
    padding-top: 130px;
    padding-bottom: 100px;
}
.custom-text{
    width: 170px;
}
.text1{
    position: absolute;
    top: -4%;
    left: 15%;
    color: #14908e;
}
.text2{
    position: absolute;
    bottom: -9%;
    left: 14.5%;
    color: #14908e;
}
.text3{
    position: absolute;
    top: -10%;
    left: 25.3%;
    color: #09768d;
}
.text4{
    position: absolute;
    bottom: -4%;
    left: 25%;
    color: #09768d;
}
.text5{
    position: absolute;
    top: -4%;
    left: 35.3%;
    color: #0e5492;
}
.text6{
    position: absolute;
    bottom: -9%;
    left: 35.5%;
    color: #0e5492;
}
.text7{
    position: absolute;
    top: -10%;
    left: 45.5%;
    color: #383085;
}
.text8{
    position: absolute;
    bottom: -4%;
    left: 46%;
    color: #383085;
}
.text9{
    position: absolute;
    top: -4%;
    left: 55.3%;
    color: #6a4e97;
}
.text10{
    position: absolute;
    bottom: -9%;
    left: 55.9%;
    color: #6a4e97;
}
.text11{
    position: absolute;
    top: -10%;
    left: 66%;
    color: #86368b;
}
.text12{
    position: absolute;
    bottom: -4%;
    left: 66%;
    color: #86368b;
}
.text13{
    position: absolute;
    top: -4%;
    left: 76%;
    color: #b8478f;
}
.text14{
    position: absolute;
    bottom: -17%;
    left: 76.9%;
    color: #b8478f;
}
@media screen and (max-width: 1600px) {
    .custom-image{
        width: 100%;
        height: 280px;
    }
    .text1{
        top: -4%;
        left: 10.3%;
    }
    .text2{
        bottom: -9%;
        left: 9.9%;
    }
    .text3{
        top: -10%;
        left: 21.6%;
    }
    .text4{
        bottom: -4%;
        left: 21%;
    }
    .text5{
        top: -4%;
        left: 33.3%;
    }
    .text6{
        bottom: -9%;
        left: 33.2%;
    }
    .text7{
        top: -10%;
        left: 44.5%;
    }
    .text8{
        bottom: -4%;
        left: 44.3%;
    }
    .text9{
        top: -4%;
        left: 56%;
    }
    .text10{
        bottom: -9%;
        left: 56.4%;
    }
    .text11{
        top: -10%;
        left: 67.7%;
    }
    .text12{
        bottom: -4%;
        left: 67.2%;
    }
    .text13{
        top: -4%;
        left: 79%;
    }
    .text14{
        bottom: -17%;
        left: 79.2%;
    }
}
@media screen and (max-width: 1536px) {
    .custom-image{
        width: 100%;
        height: 300px;
    }

    .text1{
        top: -4%;
        left: 6.5%;
    }
    .text2{
        bottom: -9%;
        left: 5.9%;
    }
    .text3{
        top: -10%;
        left: 18.7%;
    }
    .text4{
        bottom: -4%;
        left: 18.5%;
    }
    .text5{
        top: -4%;
        left: 32.3%;
    }
    .text6{
        bottom: -9%;
        left: 31.5%;
    }
    .text7{
        top: -10%;
        left: 44.5%;
    }
    .text8{
        bottom: -4%;
        left: 44.5%;
    }
    .text9{
        top: -4%;
        left: 57.3%;
    }
    .text10{
        bottom: -9%;
        left: 57.5%;
    }
    .text11{
        top: -10%;
        left: 70%;
    }
    .text12{
        bottom: -4%;
        left: 70%;
    }
    .text13{
        top: -4%;
        left: 83%;
    }
    .text14{
        bottom: -17%;
        left: 82.9%;
    }
   
}
@media screen and (max-width: 1400px) {
    .custom-image{
        width: 100%;
        height: 269px;
    }
}
@media screen and (max-width:1280px) {
    .custom-image{
        width: 100%;
        height: 250px;
    }
    .text1{
        top: -4%;
        left: 4.3%;
    }
    .text2{
        bottom: -9%;
        left: 3.9%;
    }
    .text3{
        top: -10%;
        left: 17.2%;
    }
    .text4{
        bottom: -4%;
        left: 17%;
    }
    .text5{
        top: -4%;
        left: 30.3%;
    }
    .text6{
        bottom: -9%;
        left: 30.4%;
    }
    .text7{
        top: -10%;
        left: 43.5%;
    }
    .text8{
        bottom: -4%;
        left: 43.3%;
    }
    .text9{
        top: -4%;
        left: 55.8%;
    }
    .text10{
        bottom: -9%;
        left: 56.5%;
    }
    .text11{
        top: -10%;
        left: 69%;
    }
    .text12{
        bottom: -4%;
        left: 69.2%;
    }
    .text13{
        top: -4%;
        left: 82%;
    }
    .text14{
        bottom: -17%;
        left: 82.5%;
    }
   
}
@media screen and (max-width: 600px) {
  .custom-logo{
      display: none;
  }
  .custom-soft-img{
    width: 100%;
}
}
