.grameenphone{
  width: 150px;
}
.banglalink{
  width: 130px;
}
.robi{
  width: 130px;
  margin-top: -40px;
}
.airtel{
  width: 130px;
  margin-top: -40px;
}
.teletalk{
  width: 170px;
  margin-bottom: -15px;
}
.tech-div{
width: 250px;
}
@media screen and (max-width: 600px) {
  .about-img{
      width: 300px;
      height: 300px;
  }
  .tech-div{
    width: 100%;
    }
    .robi{
      margin-top: 0px;
    }
    .airtel{
      margin-top: 0px;
    }
    .teletalk{
      margin-bottom: 0px;
      margin-top: -15px;
    }
}