.technology{
    border-right: 4px solid rgba(237, 232, 232, 0.807);
    padding-right: 20px;
    height: 140px;
}
.react-logo{
    width: 80px;
}
.express-logo{
    width: 230px;
}
.node-logo{
    width: 160px;
}
.laravel-logo{
    width: 80px;
}
.php-logo{
    width: 120px;
}
.mongodb-logo{
    width: 170px;
}
.mysql-logo{
    width: 140px;
}

@media screen and (max-width: 1280px) {
    .react-logo{
        width: 70px;
    }
    .express-logo{
        width: 200px;
    }
    .node-logo{
        width: 150px;
    }
    .laravel-logo{
        width: 70px;
    }
    .php-logo{
        width: 100px;
    }
    .mongodb-logo{
        width: 150px;
    }
    .mysql-logo{
        width: 120px;
    }
}
@media screen and (max-width: 600px) {
    .react-logo{
        width: 70px;
    }
    .express-logo{
        width: 50%;
    }
    .node-logo{
        width: 150px;
    }
    .laravel-logo{
        width: 70px;
    }
    .php-logo{
        width: 100px;
    }
    .mongodb-logo{
        width: 120px;
    }
    .mysql-logo{
        width: 110px;
    }
}