.headerText{
    background: linear-gradient(currentColor 0 0) bottom left/ var(--underline-width, 0%) 0.1em no-repeat;
    color: rgb(14, 103, 237) !important;
    display: inline-block;
    padding: 0 0.1em;
    text-decoration: none;
    transition: all .7s ease-in-out;
  }
  .headerText:hover {
    --underline-width: 100%;
  }
  .navbar-icons{
    display: none;
  }
  .najj-logo{
    width: 100%;
    height: 50px;
  }

  @media screen and (max-width: 600px){
    .navbar-icons{
      display: block;
    }
    .navbar-second-icon{
      display: none;
    }
    .najj-logo{
      width: 90%;
      height: 35px;
    }
  }
  @media screen and (max-width: 375px){
    .najj-logo{
      width: 85%;
      height: 35px;
    }
  }