.customer-parent{
    position: relative;
    padding-top: 57px;
}
.customer-parent:hover .cusotmer-img{
    border: 4px solid rgba(164, 230, 172, 0.814);
}
.customer-child{
    position: absolute;
    left: 115px;
    top: -55px;
}
.cusotmer-img{
    width: 110px;
    height: 110px;
    border: 4px solid rgba(247, 240, 240, 0.945);
}
.feedback-bg{
    background-color: rgba(202, 239, 245, 0.797);
    transform: skewY(-1deg);
}
.feedback{
    transform: skewY(1deg);
}
.carousel-padding{
    padding-bottom: 100px;
}
.customer-margin{
    margin-top: 80px;
}
.customer-card-bg{
    background-color: aliceblue !important;
    min-height: 300px;
}
.feedback-text{
    text-align: start  !important;
}

@media screen and (max-width: 1280px) {
    .customer-child{
        position: absolute;
        left: 87px; 
        top: -52px;
    }
    .cusotmer-img{
        width: 100px;
        height: 100px;
    }
  }
  @media screen and (max-width: 600px) {
    .customer-child{
        position: absolute;
        left: 53px;
        top: -55px;
    }
    .cusotmer-img{
        width: 100px;
        height: 100px;
    }
  }