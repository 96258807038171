.about-text{
    text-align: justify;
}
.about-margin{
    margin-top: 80px;
}
.about-img{
    position: relative;
    width: 450px;
    height: 400px;
}
  .grid-container > div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    color: red;
  }
  
  .grid-container > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .image-3 {
    grid-area: image-3;
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-animation: swinging 4.5s ease-in-out forwards infinite;
    animation: swinging 4.5s ease-in-out forwards infinite;
  }
  
  .image-3:hover {
    animation-play-state: paused;
  }
  
  @keyframes swinging {
    0% {
      transform: rotate(10deg);
    }
    50% {
      transform: rotate(-10deg);
    }
    100% {
      transform: rotate(10deg);
    }
  }

  .about-design-bottom{
    border-bottom: 1px solid rgb(247, 20, 20);
    padding-bottom: 30px;
  }
  .about-design-left{
    border-left: 3px solid rgba(219, 58, 58, 0.867);
    padding-right: 20px;
    margin-top: 15px;
  }

@media screen and (max-width: 600px) {
    .about-img{
        width: 300px;
        height: 300px;
    }
    @keyframes swinging {
        0% {
          transform: rotate(5deg);
        }
        50% {
          transform: rotate(-5deg);
        }
        100% {
          transform: rotate(5deg);
        }
      }
  }