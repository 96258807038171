.facebook{
    background-color: deepskyblue !important;
}
.youtube{
    background-color: red !important;
}
.google{
    background-color: rgba(129, 194, 215, 0.727) !important;
}
.border-line{
    border-right: 2px solid rgb(168, 198, 237);
}
.bg-footer{
    background-color: azure;
}
.footer-image{
    height: 80px;
}

@media screen and (max-width: 600px) {
    .border-line{
        border-right: 0px;
        border-bottom: 2px solid rgb(168, 198, 237);
    }
  }