.facebook-icon{
    font-size: 32px;
}
.linkind-icon{
    font-size: 37px;
}
.team-top{
    height: 100px;
    background-color: 10px 10px 5px whitesmoke;
    transition: all 1.2s ease-in-out; 
}
.team-bottom{
    background-color: 10px 10px 5px whitesmoke;
    height: 300px;
    margin-top: 20px;
    transition: all 1.2s ease-in-out;
}
.team-img{
    width: 135px;
    height: 135px;
}
.team-parent{
    position: relative;
}
.team-card{
    width: 300px;
}
.team-card:hover .team-top{
    height: 120px; 
    transition: all 1.2s ease-in-out;    
}
.team-card:hover .team-bottom{
    margin-top: 0px;
    transition: all 1.2s ease-in-out;   
}
.team-child{
    position: absolute;
    top: 35px;
    left: 80px;
}
.team-title{
    margin-top: 40px;
    color: white;
    background: rgba(161, 145, 217, 0.756);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-end-end-radius: 30px;
    border-end-start-radius: 30px;
    transition: all 1.2s ease-in-out;
}
.team-card:hover .team-title{
    margin-top: 40px;
    color: white;
    background: rgba(161, 145, 217, 0.756);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-end-end-radius: 0px;
    border-end-start-radius: 0px;  
    transition: all 1.2s ease-in-out;
}
@media screen and (max-width: 1600px) {
    .team-bottom{
       height: 300px;
    }
}
@media screen and (max-width: 1536px) {
    .team-child{
        top: 30px;
        left: 78px;
    }
}
@media screen and (max-width: 1400px) {
    .team-child{
        top: 30px;
        left: 67px;
    }
}
@media screen and (max-width: 1280px) {
    .team-child{
        top: 34px;
        left: 51px;
    }
    .team-bottom{
        height: 330px;
    }
    .team-img{
        width: 130px;
        height: 130px;
  }
}
  
@media screen and (max-width: 600px) {
    .team-child{
        top: 30px;
        left: 100px;
    }
    .team-card{
        width: 100%;
    }
  }
@media screen and (max-width: 414px) {
    .team-child{
        top: 30px;
        left: 126px;
    }
    .team-card{
        width: 100%;
    }
  }
@media screen and (max-width: 393px) {
    .team-child{
        top: 30px;
        left: 115px;
    }
    .team-card{
        width: 100%;
    }
  }
@media screen and (max-width: 360px) {
    .team-child{
        top: 30px;
        left: 100px;
    }
    .team-card{
        width: 100%;
    }
  }
