.product-bottom{
    margin-bottom: 60px;
}
.sms-text-color{
    color: #00B1DA;
}
.tutor-text-color{
    color: #008593;
}
.protiva-text-color{
    color: #ED2024;
}
.bg-tutory-logo{
    background-color: whitesmoke;
}
.bg-protiva-logo{
    background-color: whitesmoke;
}

.product-img{
    width: 170px;
    height: 170px;
    border: 4px solid #01B0D8;
}
.product-img-tutory{
    width: 170px;
    height: 170px;
    border: 5px solid #008593;
}
.product-img-protiva{
    width: 170px;
    height: 170px;
    border: 5px solid rgb(230, 68, 68);
}

.product-image{
    width: 100%;
    height: 600px;
}
.image-div{
    position: relative;
    width: 940px;
    height: 650px;
}
.product-parent{
    display: flex;
}
.product-2nd-parent{
    display: flex;
}
.product-logo{
position: absolute;
right: -80px;
top: 250px;
}
.bg-product-sms{
    background-color: #F5F5FF;
}
.bg-btn-sms{
    background-color: #a0a0e5 !important; 
}
.bg-product-tutory{
    background-color: #ECFDFF;
}
.bg-btn-tutory{
    background-color: #69dae7 !important; 
}
.bg-product-protiva{
    background-color: #FDF7F7;
}
.bg-btn-protiva{
    background-color: #ed9d9d !important; 
}
.text-padding{
   padding-left: 100px;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 14px;
    line-height: 20px;
}
.tutory-div{
    position: relative;
}
.tutory-logo{
    position: absolute;
    right: -80px;
    top: 250px;
    z-index: 1;
}
.protiva-logo{
    position: absolute;
    right: -80px;
    top: 250px;
    z-index: 1;
}

@media screen and (max-width: 1600px) {
    .product-img{
        width: 160px;
        height: 160px;
    }
    .image-div{
        width: 790px;
        height: 630px;
    }
  }
@media screen and (max-width: 1536px) {
    .product-img{
        width: 160px;
        height: 160px;
    }
    .image-div{
        width: 755px;
        height: 630px;
    }
  }
@media screen and (max-width: 1400px) {
    .product-img{
        width: 160px;
        height: 160px;
    }
    .image-div{
        width: 690px;
        height: 630px;
    }
    .text-padding{
        padding-left: 80px;
    }  
  }
@media screen and (max-width: 1280px) {
    .product-img{
        width: 160px;
        height: 160px;
    }
    .image-div{
        width: 620px;
        height: 630px;
    }
    .product-logo{
        top: 300px;
    }
    .tutory-logo{
        top: 300px;
    }
    .protiva-logo{
        top: 300px;
    }
    .text-padding{
        padding-left: 80px;
    }    
  }
@media screen and (max-width: 600px) {
    .product-parent{
        display: block;
        margin-bottom: 70px;
    }
    .product-2nd-parent{
        flex-direction: column-reverse;
        margin-bottom: 70px;
    }
    .protiva-res-margin{
        margin-bottom: 10px;
    }
    .product-image{
        width: 100%;
        height: 350px;
    }
    .product-img-tutory{
        width: 130px;
        height: 130px;
    }
    .product-img-protiva{
        width: 130px;
        height: 130px;
    }
    .image-div{
        width: 100%;
        height: 450px;
    }
    .product-logo{
        right: 100px;
        top: -88px;
    }
    .protiva-logo{
        right: 100px;
        top: -88px;
    }
    .tutory-logo{
        right: 100px;
        top: -556px;
    }
    .product-img{
        width: 130px;
        height: 130px;
    }
    .text-padding{
        padding-left: 10px;
    }    
  }
  @media screen and (max-width: 414px) {
    .product-logo{
        right: 120px;
        top: -88px;
    }
    .protiva-logo{
        right: 120px;
        top: -88px;
    }
    .tutory-logo{
        right: 120px;
        top: -556px;
    }
  }
