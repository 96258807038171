.title {
  padding-top: 300px;
}
.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 65px;
}
.bg-image{
  height: 94vh;
  background-position: center;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

@media screen and (max-width: 600px) {
  .title {
    padding-top: 30px;
  }
  .bg-image{
    height: 40vh;
    background-position: top;
  }
  .animate-charcter{
    font-size: 40px;
  }
  .title-bottom{
    font-size: 18px;
  }
}